import type { Modal } from 'bootstrap';

export const useBootstrapModal = (
  modalContainer: Ref<HTMLElement>,
  onModalHidden: () => void = () => undefined,
) => {
  const modalInstance = ref<Modal>();
  const instanceAvailable = ref(false);

  useEventListener(modalContainer, 'hidden.bs.modal', onModalHidden);

  onMounted(async () => {
    const { default: BsModal } = await import('bootstrap/js/dist/modal');
    modalInstance.value = new BsModal(modalContainer.value);
    instanceAvailable.value = true;
  });

  onUnmounted(() => {
    if (modalInstance.value) {
      modalInstance.value.dispose();
    }
  });

  const openModal = () => {
    modalInstance.value.show();
  };

  const closeModal = () => {
    modalInstance.value.hide();
  };

  return {
    modalInstance,
    instanceAvailable,
    openModal,
    closeModal,
  };
};

export default useBootstrapModal;
